import React, { useEffect, useState } from "react";
import { getlocations } from "../../api/unit";
import { useNavigate, useLocation } from "react-router-dom";
import { defColor } from "../../Constants/defaults";
import { t } from "i18next";

export default function BuySearch({ type }) {
  const types = ["Apartment", "Villa", "Commercial", "Land", "Second Homes", "Studio", "Duplex", "Office", "Clinics"];
  const rooms = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "10+"];
  const bathrooms = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const type1 = ["Primary", "Resale"];
  const [locations, setlocations] = React.useState([]);
  const location = useLocation();
  const [units, setUnits] = useState([])

  React.useEffect(() => {
    getlocations().then((result) => {
      setlocations(result.data.response);
    });
  }, []);
  React.useEffect(() => {
    setFormdata({ ...formdata, ["selltype"]: type });
  }, [type]);
  const navigate = useNavigate()
  const [formdata, setFormdata] = React.useState({
    selltype: type,
    type2: location.state?.formdata?.type2 || "",
    location: location.state?.formdata?.location || "6 October",
    minPrice: location.state?.formdata?.minPrice,
    maxPrice: location.state?.formdata?.maxPrice,
    minArea: location.state?.formdata?.minArea,
    maxArea: location.state?.formdata?.maxArea,
    rooms: location.state?.formdata?.rooms || "",
    bathrooms: location.state?.formdata?.bathrooms || "",
    type: location.state?.formdata?.type || "Primary",
  });

  const handelsubmit = (e) => {
    e.preventDefault();
    navigate("/query", {
      state: { formdata: formdata },
    });
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    if (+value >= 0) {
      setFormdata({ ...formdata, [name]: +value });
    } else {
      setFormdata({ ...formdata, [name]: 0 });
    }
  };

  return (
    <form className=" px-3 py-2  m-sm-3 " onSubmit={handelsubmit}>
      <div className="form-row">
        <div className="form-group col-lg-2 col-md-12 col-sm-12 ">
          {/* <label className="font-weight-bold  text-light" for="type">
            Type
          </label> */}
          <select
            className="form-select form-control form-select-lg "
            aria-label=".form-select-lg example"
            name="type2"
            id="type2"
            value={formdata?.type2}
            onChange={handleTextChange}
          >
            <option disabled={true} value="">{t('choose_property_type')}</option>
            {types.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>
        <select
          className="form-control mx-1 my-1 col-lg-3 col-md-12 col-sm-12"
          aria-label=".form-select-lg example"
          name="location"
          onChange={handleTextChange}
          value={formdata.location}
        >
          {locations.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>

        <input
          type="number"
          className="form-control mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          placeholder="Min Price"
          name="minPrice"
          value={formdata.minPrice}
          onChange={handleNumberChange}
        />

        <input
          type="number"
          className="form-control mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          placeholder="Max Price"
          name="maxPrice"
          value={formdata.maxPrice}
          onChange={handleNumberChange}
        />

        <input
          type="number"
          className="form-control mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          placeholder="Min Area"
          name="minArea"
          value={formdata.minArea}
          onChange={handleNumberChange}
        />

        <input
          type="number"
          className="form-control mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          placeholder="Max Area"
          name="maxArea"
          value={formdata.maxArea}
          onChange={handleNumberChange}
        />

        <select
          className="form-select form-control form-select-lg mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          aria-label=".form-select-lg example"
          name="rooms"
          value={formdata.rooms}
          onChange={handleTextChange}
        >
          <option disabled={true} value={""}>{t('rooms')}</option>
          {rooms.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>

        <select
          className="form-select form-control form-select-lg mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          aria-label=".form-select-lg example"
          name="bathrooms"
          value={formdata.bathrooms}
          onChange={handleTextChange}
        >
          <option disabled={true} value={""}>{t('bathrooms')}</option>
          {bathrooms.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
        <select
          className="form-select form-control form-select-lg mx-1 my-1 col-lg-2 col-md-5 col-sm-5"
          aria-label=".form-select-lg example"
          name="type"
          value={formdata.type}
          onChange={handleTextChange}
        >
          {type1.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
        {/* className="col-md-12 mt-2" */}
        <div className="col-lg-1 col-md-12 my-1">
          <button className="btn w-100 h-100" style={{
            color: "#fff", backgroundColor: defColor, borderColor: defColor
          }}>
            <i className="fas fa-search fa-lg d-none d-lg-block center"></i>
            <label className="d-block d-lg-none">Search</label>
          </button>
        </div>
      </div>
    </form>
  );
}
