import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { defColor } from "../../Constants/defaults";
import { assigenhome, removehome, deleteunit } from "../../api/unit";
export default function AdminCard({
  id,
  destination,
  adname,
  size,
  price,
  imagee,
  description,
  home,
  date,
  isExist,
  updateids,
  addOrRemoveIdToList,
}) {
  const [inHome, setInHome] = useState(false);
  useEffect(() => {
    setInHome(home.includes(id));
  }, [home, id]);
  return (
    <div className="card " style={{ width: "20rem", height: "31rem" }}>
      <img
        className="card-img-top w-100 imgCardAnimation"
        style={{ height: "15rem" }}
        src={imagee}
        alt="Unit"
      />

      <div className="card-body h-50">
        <div style={{ height: "25%" }}>
          <h5 className="card-title m-0 h-100 overflow-hidden">{adname}</h5>
        </div>
        {/* <p className="text-secondary m-0 overflow-hidden h-75">
              {description}
            </p> */}

        <hr />
        <label style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
          <input
            type="checkbox"
            checked={isExist}
            onChange={() => {
              addOrRemoveIdToList(id)
            }}
          />
          Select
        </label>

        <div
          style={{ height: "12%" }}
          className="d-flex justify-content-between"
        >
          <p className="m-0">
            {price?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
            E.G.P
          </p>
          <p className="m-0">
          </p>
          <p className="m-0">{size} ㎡</p>
        </div>
        <div
          style={{
            height: "16%",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            //border: "solid",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <Link className="btn" to={`/details/${id}`} style={{
            color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
          }}>
            Details
          </Link>
          <Link className="btn" to={`/postAd/${id}`} style={{
            color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
          }}>
            Edit
          </Link>
          <button className="btn" type="button" onClick={() => {
            deleteunit(id).then(() => { window.location.reload() })
          }} style={{
            color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
          }}>Delete</button>
        </div>
        <div
          className="my-3 d-flex justify-content-between align-items-center"
          style={{ height: "15%" }}
        >
          <h5>Show In Home?</h5>
          <i
            className={`${inHome
              ? "fa-solid fa-circle-check fa-xl"
              : "fa-regular fa-circle-check fa-xl"
              } `}
            style={{ color: defColor, cursor: "pointer" }}
            onClick={() => {
              if (inHome) {
                removehome(id).then((e) => {
                  updateids();
                });
              } else {
                assigenhome(id).then((e) => {
                  updateids();
                });
              }
            }}
          ></i>
        </div>
      </div>
    </div>
  );
}
