import React from "react";
import Logo from '../Images/logo.png'
import { defColor } from "../Constants/defaults";

export default function Footer() {
  return (
    <footer className="text-center text-lg-start text-muted p-1">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3" style={{
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div className="">
              <img src={Logo} alt="Logo" style={{
                maxWidth: '300px', opacity: "0.7"
              }} />
            </div>
            <div className="">
              <h6 className="fw-bold mb-4" style={{ color: defColor }}>For Companies</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">Posting Ad </h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">Reach to all users</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">Grow your Business now</h6>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="fw-bold mb-4" style={{ color: defColor }}>For Persons</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">
                Access For All Units
              </h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">Chat With all owners</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-dark">Get Your Optimal Unit</h6>
            </div>
          </div>
        </div>
      </section>
      <div style={{ width: "100%", justifyContent: "end", display: "flex" }}>
        <div style={{ backgroundColor: defColor, margin: "5px", color: "#fff", borderRadius: "10px" }}>
          <i className="fa-brands fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i>
        </div>
        <div style={{ backgroundColor: defColor, margin: "5px", color: "#fff", borderRadius: "10px" }}>
          <i className="fa-brands fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i>
        </div>
        <div style={{ backgroundColor: defColor, margin: "5px", color: "#fff", borderRadius: "10px" }}>
          <i className="fa-brands fa-x-twitter" style={{ fontSize: "25px", padding: "10px" }}></i>
        </div>
        <div style={{ backgroundColor: defColor, margin: "5px", color: "#fff", borderRadius: "10px" }}>
          <i className="fa-brands fa-linkedin-in" style={{ fontSize: "25px", padding: "10px" }}></i>
        </div>
      </div>
    </footer>
  );
}