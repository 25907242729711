import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { create } from "../../api/wishlist";
import { Cookies } from "react-cookie";
import './unitCardAll.css'
import { defColor } from "../../Constants/defaults";

export default function UnitCardAll({
  id,
  destination,
  adname,
  size,
  price,
  imagee,
  description,
  update_favorites, favorites, myUnits
}) {
  const cookie = new Cookies();


  const [dir, setDir] = useState('rtl');

  useEffect(() => {
    if (document.body.dir === 'rtl') {
      setDir('rtl')
    }
    else setDir('ltr')
  }, [])

  return (
    <div>
      <div
        className="card unit-card mouseHover "
        style={{ height: "400px", border: `1px solid ${defColor}` }}
      >
        <div className={`${dir === 'rtl' ? 'dir-right' : 'dir-left'}`}
          onClick={() => {
            if (cookie.get("user")) {
              create(id);
              update_favorites()
            } else {
              alert("You have to log in first");
            }
          }}
          style={{
            position: "absolute",
            bottom: "3px",
            right: "5px",
            zIndex: 0.5,
          }}
        >
          <i
            className={`${favorites?.wish?.includes(id) ? "fa-solid" : "fa-regular"
              } fa-heart fa-lg`}
            style={{ color: defColor }}
          ></i>
        </div>
        <Link
          to={destination}
          className="text-dark"
          style={{ textDecoration: "none", height: "100%", width: "100%" }}
        >
          <div className="h-50">
            <img
              className="card-img-top w-100 h-100  imgCardAnimation"
              src={imagee}
              alt="Unit"
            />
          </div>
          <div className="card-body h-50 mb-1">
            <div style={{ height: "75%", margin: 0 }}>
              <h5 className="card-title  h-50 mb-3" style={{ textAlign: 'start' }}>{adname}  </h5>

              <p
                className="text-secondary overflow-hidden"
                style={{ height: "45%", textAlign: 'start' }}
              >
                {description}
              </p>
            </div>
            <hr />

            <div
              style={{ height: "20%", margin: 0, width: "90%" }}
              className="d-flex justify-content-between"
            >
              <p className="m-0">
                {price?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                E.G.P
              </p>
              <p className="m-0">{size} ㎡</p>
            </div>
          </div>
        </Link>
      </div>
      {myUnits && <Link className="btn" to={`/postAd/${id}`} style={{
        color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
      }}>
        Edit
      </Link>}
    </div>
  );
}
