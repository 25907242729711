import React from "react";
import ShowUnits from "../Components/ShowUnits";
import { useEffect } from "react";
import { getmy } from "../api/unit";
import { useState } from "react";

export default function YourUnits() {
  const [units, setUnits] = useState([]);

  const getMyUnits = (sort) => {
    getmy(sort).then((result) => {
      setUnits(result.data.response);
    });
  }

  useEffect(() => {
    getMyUnits("")
  }, []);
  return <ShowUnits sortBy={getMyUnits} fav={false} units={units} myUnits={true} />;
}
