import React from "react";
import BuyOrSellUnits from "./Home/BuyOrSellUnits";
import BuySearch from "./Home/BuySearch";
import UnitsCards from "./AllUnits/UnitsCards";
import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";
import { defColor } from "../Constants/defaults";

export default function ShowUnits({ title, units, showfav, update_favorites, favorites, myUnits, sortBy }) {
  return (
    <div className="w-75 m-auto my-4">
      {!showfav && <div className="tabContainer my-5">
        <BuyOrSellUnits
          config={[
            { header: "Buy", content: <BuySearch type={"Sell"} /> },
            { header: "Rent", content: <BuySearch type={"Rent"} /> },
          ]}
        />
      </div>}
      <h1 className="text-center text-dark">{title}</h1>
      <div style={{
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "end"
      }}>
        <div type="button"
          id="dropdownMenuButton2"
          data-toggle="dropdown"
          aria-expanded="false">
          <p style={{ color: defColor, display: "inline" }}>Filter</p>
          <CiFilter style={{
            color: defColor,
            fontSize: "25px"
          }} />
        </div>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
          <li className="dropdown-item" style={{ color: "#000" }} onClick={() => sortBy("price_ascending")} type="button">
            Price ascending
          </li>
          <li className="dropdown-item" style={{ color: "#000" }} onClick={() => sortBy("price_descending")} type="button">
            Price descending
          </li>

          <li className="dropdown-item" style={{ color: "#000" }} onClick={() => sortBy("date_ascending")} type="button">
            Date ascending
          </li>
          <li className="dropdown-item" style={{ color: "#000" }} onClick={() => sortBy("date_descending")} type="button">
            Date descending
          </li>
        </ul>
      </div>
      <div>
        <UnitsCards units={units} update_favorites={update_favorites} favorites={favorites} myUnits={myUnits || false} />
      </div>
    </div>
  );
}
