import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import Logo from "../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import DropDownIcon from "./DropDownIcon";
import LangIcon from './images/languageIcon.svg';
import './navbar.css';
import 'flag-icon-css/css/flag-icons.min.css';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { defColor } from "../Constants/defaults";

const langauges = [
  {
    code: 'ar',
    name: 'العربيه',
    country_code: 'eg',
    dir: 'rtl',
  },
  {
    code: 'en',
    name: 'english',
    country_code: 'us',
    dir: 'ltr',
  },
];

export default function Navbar({ toggleModeLogin, toggleModelSignup }) {
  const [showSearchField, setShowSearchField] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActieTab] = useState(1);
  const [showLangList, setShowLangList] = useState(false)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const query = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (!query) {
      setActieTab(1);
    } else if (query === "jobs") {
      setActieTab(5);
    }
  }, [query]);

  const toggleSearchField = () => {
    setShowSearchField(!showSearchField);
  };

  const [dir, setDir] = useState('rtl');

  const handleLangList = (value, dir) => {
    document.body.dir = dir;
    setDir(dir)
    i18next.changeLanguage(value);
    setShowLangList(false);
  }

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "#fff", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
      >
        <div className="mx-3">
          <Link onClick={() => {
            cookie.remove("typeUnit")
            window.location.replace("/");
          }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ Width: "200px", maxHeight: "75px" }}
            />
          </Link>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fas fa-bars" style={{ color: defColor }}></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto">
            <li
              className={`nav-item ${activeTab === 1 ? "active" : ""}`}
              id={1}
              onClick={() => setActieTab(1)}
            >
              <Link
                style={{ fontSize: "22px", color: defColor }}
                className={`nav-link ${activeTab === 1 ? "font-weight-bold" : ""
                  }`}
                onClick={() => {
                  cookie.remove("typeUnit")
                  window.location.replace("/");
                }}
              >
                {t('home')}
              </Link>
            </li>

            {/**<li
              className={`nav-item ${activeTab === 3 ? "active" : ""}`}
              id={5}
              onClick={() => setActieTab(3)}
            >
              <Link
                style={{ fontSize: "22px", color: "#B79763" }}
                className={`nav-link ${activeTab === 3 ? "font-weight-bold" : ""
                  }`}
                to={"https://2dealcrm.com"}
              >
                {t('crm_system')}
              </Link>
            </li>**/}
            {/**<li
              className={`nav-item ${activeTab === 5 ? "active" : ""}`}
              id={5}
              onClick={() => setActieTab(5)}
            >
              <Link
                style={{ fontSize: "22px", color: defColor }}
                className={`nav-link ${activeTab === 5 ? "font-weight-bold" : ""
                  }`}
                to={"/jobs"}
              >
                {t('jobs_hiring')}
              </Link>
            </li>**/}
          </ul>
          {/* Search icon */}
          <div className="mx-2">
            <i className="fas fa-search" style={{ color: defColor }} onClick={toggleSearchField}></i>
          </div>

          {/* Search field */}
          {showSearchField && (
            <div className="mx-2">
              <form onSubmit={(e) => {
                e.preventDefault()
                if (searchValue !== "")
                  navigate(`/search/${searchValue.replaceAll("#", "")}`)
              }}>
                <input style={{ direction: dir }} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="text" placeholder={dir === 'ltr' ? "Search..." : "...بحث"} />
              </form>
            </div>
          )}
          <div className="mx-2  nav-lang ">
            <img src={LangIcon} alt="" width={28} onClick={() => setShowLangList(!showLangList)} />
            {showLangList &&
              <ul className={`list-lang ${dir === 'rtl' ? 'dir-right' : 'dir-left'}`}>
                {langauges.map(({ code, name, country_code, dir }) => {
                  return (
                    <li className='d-flex align-items-center' key={country_code}
                      onClick={() => handleLangList(code, dir)}>
                      <span className={`flag-icon flag-icon-${country_code}`}></span>
                      <span className='mx-2'>{name}</span>
                    </li>
                  )
                })}
              </ul>
            }
          </div>
          {activeTab === 5 ? (
            <>
              {cookie.get("jobsUser") ? (
                <button
                  type="button"
                  className="mx-2 btn btn-outline-danger"
                  onClick={() => {
                    cookie.remove("jobsAuth", { path: "/" });
                    cookie.remove("jobsUser", { path: "/" });
                    navigate("/jobs", { replace: true });
                  }}
                >
                  {t('logout')}
                </button>
              ) : (
                <div></div>
              )}
            </>
          ) : (
            <>
              {cookie.get("user") ? (
                <>
                  <DropDownIcon />
                  <Link to={"/postAd"}>
                    <button
                      type="button"
                      className="mx-2 btn btn-outline-danger"
                      style={{ color: defColor, borderColor: defColor }}
                    >
                      Posting an ad
                    </button>
                  </Link>
                </>
              ) : activeTab === 3 ? (
                <></>
              ) : (
                <div>
                  <div className="my-2">
                    <button
                      type="button"
                      className=" mx-1 btn btn-light"
                      style={{ backgroundColor: "darkgrey", color: "white" }}
                      onClick={toggleModeLogin}
                    >
                      {t('login')}
                    </button>

                    <button
                      type="button"
                      className="mx-1 btn "
                      style={{
                        color: "#fff",
                        backgroundColor: defColor,
                        borderColor: defColor,
                      }}
                      onClick={toggleModelSignup}
                    >
                      {t('sign_up')}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {/* <DropDownIcon /> */}
        </div>
      </nav>
    </div>
  );
}