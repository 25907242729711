import React from "react";

import AdminCard from "../../Components/Dashboard/AdminCard";
import { getall, gethomeids, adminquery, filterbyDate, deletemany } from "../../api/unit";
import { useEffect } from "react";
import { useState } from "react";
import { proxy } from "../../config";
import SearchBar from "../../Components/Dashboard/SearchBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./manage.css"
import { useLocation } from "react-router-dom";

export default function ManageUnits() {
  const [units, setUnits] = useState([]);
  const [ids, setids] = useState([]);
  const { state } = useLocation();
  const [startDate, setStartDate] = useState(localStorage.getItem("searchDate") || new Date());
  const [startDate2, setStartDate2] = useState(new Date());

  const updateids = async () => {
    gethomeids().then((result) => setids(result.data.response));
  };
  useEffect(() => {
    // window.location.reload();
    if (localStorage.getItem("searchText") && localStorage.getItem("searchText") !== "") {
      adminquery(searchValue).then((result) => {
        setUnits(result.data.response);
      });
    } else if (localStorage.getItem("searchDate") && localStorage.getItem("searchDate") !== "") {
      onSearchByDate(localStorage.getItem("searchDate"))
    } else {
      getall().then((result) => setUnits(result.data.response));
    }
    updateids();
  }, []);

  const [searchValue, setSearchValue] = useState(localStorage.getItem("searchText") || "");
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearchPress = () => {
    if (searchValue.length > 4) {
      localStorage.setItem("searchDate", "")
      localStorage.setItem("searchText", searchValue)
      setSelectedIds([])
      setIsChecked(false)
      setStartDate(new Date())
      adminquery(searchValue).then((result) => {
        console.log(result.data);
        setUnits(result.data.response);
      });
    }
  };

  const onSearchByDate = (date) => {
    localStorage.setItem("searchText", "")
    setStartDate(new Date(date).toLocaleDateString("zh-Hans-CN").replaceAll("/", "-"))
    const dateString = new Date(date).toLocaleDateString("zh-Hans-CN").replaceAll("/", "-")
    setSelectedIds([])
    setIsChecked(false)
    setSearchValue("")
    localStorage.setItem("searchDate", dateString)
    filterbyDate(dateString).then((result) => setUnits(result.data.units))
  };

  const deleteManyUnits = () => {
    deletemany(selectedIds).then((result) => {
      console.log(result)
      setSelectedIds([])
      setIsChecked(false)
      if (searchValue !== "") {
        onSearchPress()
      } else if (startDate !== new Date(startDate2).toLocaleDateString("zh-Hans-CN").replaceAll("/", "-")) {
        onSearchByDate(startDate)
      } else {
        getall().then((result) => setUnits(result.data.response));
      }
    });
  };

  const [isChecked, setIsChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleChange = () => {
    if (selectedIds.length === units.length) {
      setSelectedIds([])
      setIsChecked(selectedIds.length === units.length);
    } else {
      setSelectedIds((prevSelectedIds) => {
        // Collect all unit IDs
        const allUnitIds = units.map(unit => unit._id);

        // Add unit IDs to the selectedIds array (without duplicates)
        return [...new Set([...prevSelectedIds, ...allUnitIds])];
      });

      // Set isChecked based on the length of selectedIds vs units length
      setIsChecked(selectedIds.length === units.length);
    }
  };

  useEffect(() => {
    if (units.length !== 0) {
      setIsChecked(selectedIds.length === units.length);
    }
    console.log(selectedIds); // This will log updated selectedIds whenever it changes
  }, [selectedIds, units]);

  const addOrRemoveIdToList = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // Remove the id from the list if it's already present
        return prevSelectedIds.filter(itemId => itemId !== id);
      } else {
        // Add the id to the list if it's not already present
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <div className="p-3">
      <div className="w-75 m-auto">
        <SearchBar
          value={searchValue}
          onChange={handleSearchChange}
          onSearch={onSearchPress}
        />
        <div style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
          localStorage.setItem("searchText", "")
          localStorage.setItem("searchDate", "")
          setSearchValue("")
          setStartDate(new Date())
          setSelectedIds([])
          setIsChecked(false)
          getall().then((result) => setUnits(result.data.response));
        }}>See All</div>
      </div>
      <div style={{ justifyContent: "space-between", display: "flex" }}>
        {(units.length !== 0) &&
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleChange}
            />
            Select All
          </label>}

        {(selectedIds.length !== 0) && <button className="btn" type="button" onClick={() => { deleteManyUnits() }} style={{
          color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
        }}>Delete Selected units</button>}

        <DatePicker className="custom-datepicker" selected={startDate} onChange={(date) => {
          onSearchByDate(date)
        }} dateFormat={"yyyy-MM-dd"} />
      </div>
      {(units.length === 0) &&
        <div style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          color: "#000"
        }}>
          <h2>... loading ...</h2>
        </div>
      }
      <div className="row my-4">
        {units.map((item, index) => (
          <div key={item._id} className="col-lg-3 col-md-6 col-sm-12 my-2 ">
            <AdminCard
              id={item._id}
              destination={`/details/${item._id}`}
              adname={item.adname}
              size={item.size}
              price={item?.price?.value}
              date={item?.createdAt}
              imagee={proxy + "/" + item.images[0]}
              description={item.description}
              home={ids}
              isExist={selectedIds.includes(item._id)}
              updateids={updateids}
              addOrRemoveIdToList={addOrRemoveIdToList}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
