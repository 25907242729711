import React, { useEffect, useState } from "react";
import "./BuyOrSellUnits.css";
import { Cookies } from "react-cookie";

export default function BuyOrSellUnits({ config }) {
  const cookie = new Cookies();
  const [activeTab, setActiveTab] = useState(cookie.get("typeUnit") === "Sell" ? 0 : 1);
  const [dir, setDir] = useState('rtl');

  const changeType = (index) => {
    setActiveTab(index)
    cookie.set("typeUnit", index === 0 ? "Sell" : "Rent", { expires: 0 })
  }

  useEffect(() => {
    if (document.body.dir === 'rtl') {
      setDir('rtl')
    }
    else setDir('ltr')
  }, [])
  return (
    <div className="tab">
      <div className="tab-headers">
        <div
          className={`tab-header active`}
        >
          {cookie.get("typeUnit") === "Sell" ? "Buy" : cookie.get("typeUnit")}
        </div>
      </div>

      <div className={`${dir === 'rtl' ? 'dir-right' : 'dir-left'} tab-body`}>{config[activeTab].content}</div>
    </div>
  );
}
