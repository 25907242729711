import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import ShowUnits from "../Components/ShowUnits";
import { search } from "../api/unit";

export default function Search({ update_favorites, favorites }) {
  const [units, setUnits] = useState([]);
  const { query } = useParams();

  const getSearch = (sort) => {
    search(query, sort).then((result) => {
      setUnits(result.data.response);
    });
  };

  useEffect(() => {
    getSearch("");
  }, [query]);

  return <ShowUnits sortBy={getSearch} fav={true} units={units} update_favorites={update_favorites} favorites={favorites} />;
}
