import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ShowUnits from "../Components/ShowUnits";
import { filter } from "../api/unit";

export default function FilteredUnits(props) {
  const { state } = useLocation();
  const [units, setUnits] = useState([])
  const [loading, setLoading] = useState(true)


  const getSearch = (sort) => {
    setUnits([])
    setLoading(true)
    filter(state?.formdata, sort).then((result) => {
      setUnits(result.data.response)
      setLoading(false)
    });
  };

  useEffect(() => {
    getSearch()
  }, [state])

  if (units?.length !== 0)
    return (
      <ShowUnits sortBy={getSearch} title={"Search Results"} units={units} update_favorites={props.update_favorites} favorites={props.favorites} />
    );
  else return <ShowUnits title={loading ? "... Loading ..." : "Empty Query"} units={[]} update_favorites={props.update_favorites} favorites={props.favorites} />;
}
