import "./start_page.css";
import Logo from "../../Images/logo.png"
import { defColor } from "../../Constants/defaults";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function StartPage({ toggleModeLogin, toggleModelSignup }) {
  const cookie = new Cookies();

  const navigate = useNavigate()

  return (
    <div className="text-center bg-image startPage">
      <div className="p-4 text-center bg-image whiteScreen">
        <div >
          <div style={{
            position: "relative",
          }}>
            <img src={Logo} alt="Logo" style={{
              maxWidth: '300px', opacity: "0.7"
            }} />
            <div style={{
              position: "absolute",
              top: "85%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#000",
              fontSize: "20px",
              fontWeight: "600",
              width: "max-content"
            }}>
              <div style={{ lineHeight: "0.5" }}>Will find</div>
              <div style={{
                borderBottom: "2px solid", // Add an underline
                width: "fit-content",
                borderColor: defColor
              }}>what you dream of</div></div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            <div className="sellImage" style={{ cursor: "pointer" }} onClick={() => {
              if (!cookie.get("user")) {
                toggleModeLogin()
              } else {
                navigate("/postAd")
              }
            }}>
              <div>Sell</div>
            </div>
            <div className="rentImage" style={{ cursor: "pointer" }} onClick={() => {
              cookie.set("typeUnit", "Rent", { expires: 0 })
              navigate(`/Rent`)
            }}>
              <div>Rent</div>
            </div>
            <div className="buyImage" style={{ cursor: "pointer" }} onClick={() => {
              cookie.set("typeUnit", "Sell")
              navigate(`/Sell`)
            }}>
              <div>Buy</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
