import React from "react";

import PendingCard from "../../Components/Dashboard/PendingCard";
import { deletemany, getPending, updatemany } from "../../api/unit";
import { useEffect } from "react";
import { useState } from "react";
import { proxy } from "../../config";

export default function PendingUnits() {
  const [units, setUnits] = useState([]);

  const init = () => {
    getPending().then((result) => setUnits(result.data.response));
  }
  useEffect(() => {
    init()
  }, []);
  /* 
    const [searchValue, setSearchValue] = useState("");
    const handleSearchChange = (e) => {
      setSearchValue(e.target.value);
    };
  
    const onSearchPress = () => {
      if (searchValue.length > 4) {
        adminquery(searchValue).then((result) => {
          console.log(result.data);
          setUnits(result.data.response);
        });
      }
    }; */

  const [isChecked, setIsChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleChange = () => {
    if (selectedIds.length === units.length) {
      setSelectedIds([])
      setIsChecked(selectedIds.length === units.length);
    } else {
      setSelectedIds((prevSelectedIds) => {
        // Collect all unit IDs
        const allUnitIds = units.map(unit => unit._id);

        // Add unit IDs to the selectedIds array (without duplicates)
        return [...new Set([...prevSelectedIds, ...allUnitIds])];
      });

      // Set isChecked based on the length of selectedIds vs units length
      setIsChecked(selectedIds.length === units.length);
    }
  };

  const addOrRemoveIdToList = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // Remove the id from the list if it's already present
        return prevSelectedIds.filter(itemId => itemId !== id);
      } else {
        // Add the id to the list if it's not already present
        return [...prevSelectedIds, id];
      }
    });
  };

  useEffect(() => {
    if (units.length !== 0) {
      setIsChecked(selectedIds.length === units.length);
    }
    console.log(selectedIds); // This will log updated selectedIds whenever it changes
  }, [selectedIds, units]);


  const deleteManyUnits = () => {
    deletemany(selectedIds).then((result) => {
      console.log(result)
      setSelectedIds([])
      setIsChecked(false)
      getPending().then((result) => setUnits(result.data.response));
    });
  };

  const updateManyUnits = () => {
    updatemany(selectedIds).then((result) => {
      console.log(result)
      setSelectedIds([])
      setIsChecked(false)
      getPending().then((result) => setUnits(result.data.response));
    });
  };

  return (
    <div className="p-3">
      <div className="w-75 m-auto">
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          {(units.length !== 0) &&
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
              />
              Select All
            </label>}

          {(selectedIds.length !== 0) && <button className="btn" type="button" onClick={() => { deleteManyUnits() }} style={{
            color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
          }}>Delete Selected units</button>}

          {(selectedIds.length !== 0) && <button className="btn" type="button" onClick={() => { updateManyUnits() }} style={{
            color: "#fff", backgroundColor: "#B79763", borderColor: "#B79763"
          }}>Approve Selected units</button>}
        </div>
      </div>
      <div className="row my-4">
        {units.map((item, index) => (
          <div key={item._id} className="col-lg-3 col-md-6 col-sm-12 my-2 ">
            <PendingCard
              init={init}
              id={item._id}
              destination={`/details/${item._id}`}
              adname={item.adname}
              size={item.size}
              price={item?.price?.value}
              imagee={proxy + "/" + item.images[0]}
              description={item.description}
              date={item?.createdAt}
              isExist={selectedIds.includes(item._id)}
              addOrRemoveIdToList={addOrRemoveIdToList}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
